<template>
  <div class="blogdetail">
    <div class="title">{{ textdetail.title }}</div>
    <div class="contentbox" v-html="textdetail.content"></div>
  </div>
</template>

<script>
import { getNewdetailApi } from "../../network/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      textdetail: {},
    };
  },
  created() {
    this.getdetail();
  },
  computed: {
    ...mapState(["userInfo"]),
    detailId() {
      return this.$route.params?.id;
    },
  },
  watch: {
    "$route"() {
      this.getdetail();
    },
  },
  methods: {
    async getdetail() {
      let params = {
        keywords: this.detailId,
        user_id: this.userInfo.id ? this.userInfo.id : "",
      };
      const res = await getNewdetailApi(params);
      this.textdetail = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.blogdetail {
  width: 100%;
  min-height: 70vh;
  overflow: hidden;
  .title {
    width: 100%;
    height: 1rem;
    text-align: center;
    line-height: 1rem;
    color: #6ec1e4;
    font-size: 0.36rem;
    font-weight: 600;
  }
  .contentbox {
    width: 12rem;
    margin: 0 auto;
  }
}
</style>
